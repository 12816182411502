<template>
  <a-card class="card-area">
    <div class="page-form-advanced-search">
      <a-form class="ant-advanced-search-form">
        <a-row :gutter="24">
          <a-col :span="4">
            <a-form-item label="审核状态">
              <a-select v-model="queryParams.status">
                <a-select-option value="0">关闭</a-select-option>
                <a-select-option value="1">待处理</a-select-option>
                <a-select-option value="2">通过</a-select-option>
              </a-select>
            </a-form-item>
          </a-col>
          <a-col :span="10">
           <a-form-item label="创建时间">
             <range-date @change="handleDateChange" ref="createTime"></range-date>
           </a-form-item>
          </a-col>
          <!-- <a-col :span="1"></a-col> -->
          <!-- <a-col :span="3" :style="{ paddingLeft: '20px' }">
            <a-button class="email-button" type="primary" @click="showModel">推送邮箱</a-button>
          </a-col> -->
        </a-row>
				<a-row>
				  <a-col :span="12"></a-col>
				  <a-col :span="12" :style="{ textAlign: 'right' }">
				    <a-button type="primary" @click="search">查询</a-button>
				    <a-button style="margin-left: 8px" @click="reset">重置</a-button>
				  </a-col>
				</a-row>
      </a-form>
    </div>
    <!-- <div class="operator">
      <a-button type="primary" ghost>新增</a-button>
      <a-button>删除</a-button>
    </div> -->
    <a-table 
      ref="TableInfo" 
      :scroll="{ x: 900 }" 
      bordered 
      :loading="loading" 
      :columns="columns" 
      :pagination="pagination" 
      @change="handleTableChange" 
      :data-source="dataSource" 
    >
      <a-tag slot="isOpen" slot-scope="text">{{ text=='0'?"不公开":(text=='1'?"公开":"未知") }}</a-tag>
      <a-tag slot="isAnonymity" slot-scope="text">{{ text=='0'?"不匿名":(text=='1'?"匿名":"未知") }}</a-tag>
      <a-tag slot="status" slot-scope="text" :color="text=='0'?'#ff7675':(text=='2'?'#1dd1a1':'#74b9ff')">
        {{ text=='0'?"关闭":(text=='2'?"通过":"待处理") }}
      </a-tag>
      <template slot="replyOpen" slot-scope="text, record">
        <a-tag v-if="record.reply!=undefined">{{ text=='0'?"私有":"公开" }}</a-tag>
      </template>
      <template slot="operation" slot-scope="text, record">
        <a-icon type="highlight" theme="twoTone" twoToneColor="#4a9ff5" @click="editOpinionMailbox(record)" title="审核反馈"></a-icon>
        &nbsp;
        <a-icon type="eye" theme="twoTone" twoToneColor="#42b983" @click="view(record)" title="详情"></a-icon>
      </template>
    </a-table>
    <OpinionMailboxEdit ref="opinionMailboxEdit" @close="handleOpinionMailboxEditClose" @success="handleOpinionMailboxEditSuccess" :opinionMailboxEditVisiable="opinionMailboxEditVisiable" />
    <OpinionMailboxInfo :infoVisiable="infoVisiable" :opinionData="opinionData" @_close="handleInfoClose" />
		<!-- <a-modal v-model="modelVisible" title="修改接收邮箱地址" @cancel="cancelModel" @ok="changeTargetEmailBox">
			<a-input v-model="emailAddress" placeholder="email地址" />
		</a-modal> -->
  </a-card>
</template>

<script>
import RangeDate from '@/components/datetime/RangeDate'
import OpinionMailboxEdit from "./OpinionMailboxEdit"
import OpinionMailboxInfo from "./OpinionMailboxInfo"


const columns = [
  {
    title: "标题",
    dataIndex: "title",
    width: 300
  },
  {
    title: "提交人",
    dataIndex: "createUsername",
    width: 100
    // ellipsis: true
  },
  {
    title: "提交时间",
    dataIndex: "createTime",
    width: 150
    // ellipsis: true
  },
  {
    title: "对象（部门）",
    dataIndex: "deptName",
    width: 150
    // ellipsis: true
  },
  {
    title: "对象（个人）",
    dataIndex: "staffName",
    width: 100
    // ellipsis: true
  },
  {
    title: "顶（支持数）",
    dataIndex: "supporterCount",
    width: 100
    // ellipsis: true
  },
  {
    title: "是否匿名",
    dataIndex: "isAnonymity",
    scopedSlots: { customRender: 'isAnonymity' },
    width: 100
    // ellipsis: true
  },
  {
    title: "是否公开",
    dataIndex: "isOpen",
    scopedSlots: { customRender: 'isOpen' },
    width: 100
    // ellipsis: true
  },
  {
    title: "审核",
    dataIndex: "status",
    scopedSlots: { customRender: 'status' },
    width: 100
    // ellipsis: true
  },
  {
    title: "反馈",
    dataIndex: "reply",
    width: 300
  },
  {
    title: "反馈公开",
    dataIndex: "replyOpen",
    width: 100,
    scopedSlots: { customRender: 'replyOpen' },
  },
  {
    title: "操作",
    dataIndex: "operation",
    scopedSlots: { customRender: 'operation' },
    fixed: "right",
    width: 70
  }
];

export default {
  components: {RangeDate, OpinionMailboxEdit, OpinionMailboxInfo},
  data() {
    return {
      infoVisiable: false,
      loading: false,
      columns,
      dataSource: [],
      queryParams: {
        createTimeFrom: "",
        createTimeTo: ""
      },
      // formItemLayout,
      paginationInfo: null,
      pagination: {
        pageSizeOptions: ['10', '20', '30', '40', '100'],
        defaultCurrent: 1,
        defaultPageSize: 10,
        showQuickJumper: true,
        showSizeChanger: true,
        showTotal: (total, range) => `显示 ${range[0]} ~ ${range[1]} 条记录，共 ${total} 条记录`
      },
      // rowSelection,
      opinionMailboxEditVisiable: false,
      opinionData: {},
			emailAddress: undefined,
			modelVisible: false
    }
  },
  mounted() {
    this.fetch();
  },
  methods: {
    view(record) {
      console.log("显示意见信箱 => ", record);
      this.opinionData = record;
      this.infoVisiable = true;
    },
    handleInfoClose() {
      this.infoVisiable = false;
    },
    editOpinionMailbox(record) {
      this.opinionMailboxEditVisiable = true;
      this.$refs.opinionMailboxEdit.setFormFields(record);
    },
    handleOpinionMailboxEditClose() {
      this.opinionMailboxEditVisiable = false;
    },
    handleOpinionMailboxEditSuccess() {
      this.opinionMailboxEditVisiable = false;
      this.$message.success("修改意见相关成功！");
      this.fetch();
    },
    handleDateChange (value) {
      console.log("选择的意见过滤时间 ==> ", value);
      if (value) {
        this.queryParams.createTimeFrom = value[0]
        this.queryParams.createTimeTo = value[1]
      }
    },
    search () {
      // let {sortedInfo} = this
      // let sortField, sortOrder
      // // 获取当前列的排序和列的过滤规则
      // if (sortedInfo) {
      //   sortField = sortedInfo.field
      //   sortOrder = sortedInfo.order
      // }
      this.fetch({
        // sortField: sortField,
        // sortOrder: sortOrder,
        ...this.queryParams
      })
    },
    handleTableChange (pagination, filters, sorter) {
      this.paginationInfo = pagination
      this.fetch({
        ...this.queryParams
      })
    },
    fetch (params = {}) {
      this.loading = true;

      if (this.paginationInfo) {
        // 如果分页信息不为空，则设置表格当前第几页，每页条数，并设置查询分页参数
        this.$refs.TableInfo.pagination.current = this.paginationInfo.current
        this.$refs.TableInfo.pagination.pageSize = this.paginationInfo.pageSize
        params.pageSize = this.paginationInfo.pageSize
        params.pageNum = this.paginationInfo.current
      } else {
        // 如果分页信息为空，则设置为默认值
        params.pageSize = this.pagination.defaultPageSize
        params.pageNum = this.pagination.defaultCurrent
      }
      
      this.$get('suggestion', {
        ...params
      }).then((r) => {
        // console.log("意见管理==>", r.data);
        this.loading = false;
        let rows = r.data.rows;
        const pagination = { ...this.pagination };
        if(rows) {
          for (const key in rows) {
            rows[key]['key'] = +key + 1;
          }
        }
        pagination.total = r.data.total;
        this.pagination = pagination;
        this.dataSource = rows || [];
      })
    },
		reset() {
		  // 取消选中
		  this.selectedRowKeys = [];
		  // 重置分页
		  this.$refs.TableInfo.pagination.current = this.pagination.defaultCurrent;
		  if (this.paginationInfo) {
		    this.paginationInfo.current = this.pagination.defaultCurrent;
		    this.paginationInfo.pageSize = this.pagination.defaultPageSize;
		  }
		  // 重置列过滤器规则
		  this.filteredInfo = null;
		  // 重置列排序规则
		  this.sortedInfo = null;
		  // 重置查询参数
		  this.queryParams = {
		    createTimeFrom: "",
		    createTimeTo: ""
		  },
		  this.fetch();
		},
		// changeTargetEmailBox() {
		// 	// 校验邮箱是否合法
		// 	let regExp = /^([a-zA-Z]|[0-9])(\w|\-)+@[a-zA-Z0-9]+\.([a-zA-Z]{2,4})$/;
		// 	if(!this.emailAddress) {
		// 		this.$message.error("邮箱地址不能为空！");
		// 		return;
		// 	}
		// 	if(!regExp.test(this.emailAddress)) {
		// 		this.$message.error("邮箱名称不合法，请检查后输入！");
		// 		return;
		// 	}
		// 	this.$put('biz-config', { valuee: this.emailAddress })
		// 		.then(r => {
		// 			console.log("修改推送邮箱的返回值 ==> ", r.data);
		// 			this.modelVisible = false;
		// 			this.$message.success("推送邮箱地址修改成功");
		// 			this.emailAddress = undefined;
		// 		})
		// },
		// showModel() {
		// 	this.modelVisible = true
		// },
		// cancelModel() {
		// 	this.modelVisible = false;
		// 	this.emailAddress = undefined;
		// }
  }
}
</script>

<style lang="less" scoped>
@import "../../../utils/Common.less";
.page-form-advanced-search {
  // .email-button {
  //   background-color: #74b9ff;
  //   border: 1px solid #0984e3;
  // }
}
</style>