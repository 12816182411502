<template>
  <a-modal
    v-model="show"
    :centered="true"
    :keyboard="false"
    :footer="null"
    :width="1200"
    @cancel="handleCancleClick"
    title="意见详情信息"
  >
    <a-row style="margin-bottom: 10px" >
      <a-col :span="12">
        <p><span><my-icon type="opinion"/>意见标题：</span><span>{{opinionData.title ? opinionData.title : '暂无'}}</span></p>
        <p><span><my-icon type="department"/>目标部门：</span><span>{{opinionData.deptName ? opinionData.deptName : '暂无'}}</span></p>
        <p><span><my-icon type="userName"/>目标员工：</span><span>{{opinionData.staffName ? opinionData.staffName : '暂无'}}</span></p>
        <p><span><a-icon type="clock-circle"/>创建时间：</span><span>{{opinionData.createTime ? opinionData.createTime : '暂无'}}</span></p>
      </a-col>
      <a-col :span="12">
        <p><span><my-icon type="standBy"/>支持数：</span><span>{{opinionData.supporterCount ? opinionData.supporterCount : '暂无'}}</span></p>
        <p><span><my-icon type="sponsor"/>发起人：</span><span>{{sponsor}}</span></p>
        <p><span><my-icon type="public"/>是否公开：</span><span>{{isOpen}}</span></p>
      </a-col>
    </a-row>
    <a-row>
      <a-col :span="24">
        <p>
          <span><my-icon type="desc"/>问题描述：</span><br/>
          <span class="info" v-html="opinionData.content ? opinionData.content : '暂无'"></span>
        </p>
        <p>
          <span><my-icon type="jobRequirements"/>建设性意见：</span><br/>
          <span class="info">{{opinionData.measures ? opinionData.measures : '暂无'}}</span>
        </p>
        <p>
          <span><my-icon type="desiredResult"/>期望结果：</span><br/>
          <span class="info">{{opinionData.expectResult ? opinionData.expectResult : '暂无'}}</span>
        </p>
				<p><my-icon type="sponsor"/>支持人员：</p>
				<a-tag style="margin: 0 5px 5px" v-for="(data, index) of userNameList" :key="index">
				  {{ data }}
				</a-tag>
      </a-col>
    </a-row>
  </a-modal>
</template>

<script>
export default {
  name: "OpinionMailboxInfo",
  props: {
    infoVisiable: {
      require: true,
      type: Boolean,
      default: false
    },
    opinionData: {
      require: true,
      type: Object,
      default: {}
    }
  },
  data() {
    return {
			userNameList: []
    }
  },
  computed: {
    show: {
      get: function () {
        return this.infoVisiable
      },
      set: function () {
      }
    },
    sponsor: {
      get: function () {
        return this.opinionData.isAnonymity?this.opinionData.createUsername:"匿名";
      },
      set: function () {
      }
    },
    isOpen: {
      get: function () {
        return this.opinionData.isOpen?'公开':'不公开';
      },
      set: function () {
      }
    }
  },
  methods: {
    handleCancleClick () {
      this.$emit('_close')
    },
  },
	watch: {
		infoVisiable(newVal) {
			if(newVal) {
				this.$get("suggestion/support-users", {id: this.opinionData.id, tableName: "suggestion"})
					.then(r => {
						console.log("意见支持的人数 ==> ", r.data.data);
						if(r.data.data && r.data.data.length) {
							this.userNameList = r.data.data.map(item => item.username);
						}
					})
			}
		}
	}
}
</script>

<style lang="less" scoped>
.info {
  display: block;
  max-width: unset;
  font-size: 14px;
  line-height: 25px;
  color: gray;
  margin-top: 10px;
  padding: 8px;
  background: #fcfcfc;
  border: 1px #ccc dotted;
  white-space: normal;
}
i {
  margin-right: .8rem;
}
</style>
