<template>
  <a-drawer class="opinion-mailbox-edit" title="意见审核反馈" @close="onClose" :width="720" :visible="opinionMailboxEditVisiable" :maskClosable="false">

    <a-form-model ref="ruleForm" :model="form" :rules="rules">
      <a-form-model-item label="审核" prop="status" v-bind="formItemLayout" >
        <a-select v-model="form.status">
          <a-select-option value="0">关闭</a-select-option>
          <a-select-option value="1">待处理</a-select-option>
          <a-select-option value="2">通过</a-select-option>
        </a-select>
      </a-form-model-item>
      <a-form-model-item label="反馈" prop="reply" v-bind="formItemLayout">
        <a-textarea placeholder="反馈" v-model="form.reply" :rows="3" />
      </a-form-model-item>
      <a-form-model-item label="反馈公开" v-bind="formItemLayout">
        <div style="text-align: left;">
          <a-tag :color="isReplyOpen?'green':'orange'">{{ isReplyOpen?"公开":"私有" }}</a-tag>
          <a-switch size="small" :checked="isReplyOpen" @change="handleReplyOpenChange" />
        </div>
      </a-form-model-item>
    </a-form-model>

    <div class="drawer-bootom-button">
      <!-- <a-popconfirm title="确定放弃编辑？" @confirm="onClose" okText="确定" cancelText="取消">
        <a-button style="margin-right: .8rem">取消</a-button>
      </a-popconfirm> -->
			<a-button @click="onClose" style="margin-right: .8rem">取消</a-button>
      <a-button @click="handleSubmit" type="primary">提交</a-button>
    </div>
  </a-drawer>
</template>

<script>

const formItemLayout = {
  labelCol: { span: 3 },
  wrapperCol: { span: 18 }
}

export default {
  name: "OpinionMailboxEdit",
  props: {
    opinionMailboxEditVisiable: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      formItemLayout,
      form: {
        status: "1"
      },
      rules: {

      }
    }
  },
  computed: {
    isReplyOpen() {
      return this.form.replyOpen == '1' ? true : false;
    },
  },
  methods: {
    onClose() {
      // this.$refs.ruleForm.resetFields();
      this.reset();
      this.$emit("close");
    },
    reset() {
      this.$refs.ruleForm.resetFields();
      for (let key in this.form) {
        this.form[key] = undefined;
      }
    },
    handleSubmit() {
      this.$refs.ruleForm.validate(valid => {
        if (valid) {
          this.$put("suggestion", {...this.form}).then(r => {
            // console.log("修改意见相关", r.data);
            this.$emit("success");
          })
        } else {
          return false;
        }
      });
    },
    handleReplyOpenChange(val) {
      this.form.replyOpen = val?'1':'0';
    },
    setFormFields({...suggestion}) {
      // console.log("suggestion", suggestion);
      let {id, status, reply, replyOpen} = suggestion;
      this.form = {id, status, reply, replyOpen};
    }
  }
}
</script>